<template>
    <div class="view--guide-details">
        <div
            v-if="isLoading"
            class="loading-empty-state" />
        <template v-else>
            <guide-details-header
                @openGuideCopyDrawer="openGuideCopyDrawer"
                @openHistoryDrawer="openHistoryDrawer"
                @openGuideValidationModal="openGuideValidationModal"
                @openClearGuideDataModal="openClearGuideDataModal"
                @openLaunchPreviewModal="openLaunchPreviewModal"
                @openDeleteGuideModal="openDeleteGuideModal" />
            <guide-details-content @openLaunchPreviewModal="openLaunchPreviewModal" />
        </template>
        <bulk-guide-copy-drawer
            :visible="showGuideCopyModal"
            :guides="[guide]"
            :eligible-subscriptions="eligibleSubscriptions"
            :loading-source-countables="loadingSourceCountables"
            :active-is-digital-adoption="activeIsDigitalAdoption"
            :active-is-training-subscription="activeIsTrainingSubscription"
            @open="onBulkCopyOpen"
            @close="closeGuideCopyModal" />
        <guide-audit-drawer
            :visible="showHistoryDrawer"
            :selected-guide="guide"
            :revisions="guideRevisions"
            :segments="segmentsMap"
            :active-timezone="activeTimezone"
            :is-mobile-app="isMobileApp"
            :loading="isHistoryLoading"
            :selected-revision="selectedRevision"
            :current-user="user"
            :guides="guides"
            @updateDateFilter="setHistoryLoading"
            @updateSelection="updateSelection"
            @close="closeHistoryDrawer" />
        <guide-content-validation
            :visible="showGuideValidationModal"
            :guide="guide"
            @close="showGuideValidationModal = false" />
        <clear-guide-data-modal
            :visible="showClearGuideDataModal"
            :guide="guide"
            @close="showClearGuideDataModal = false" />
        <launch-preview-modal
            :visible="showLaunchPreviewModal"
            v-bind="launchPreviewModalProps"
            @close="closeLaunchPreviewModal" />
        <delete-guide-modal
            :visible="showDeleteGuideModal"
            :guide="guide"
            v-bind="deleteGuideModalProps"
            @close="closeDeleteGuideModal" />
    </div>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import GuideDetailsHeader from '@/components/guides/GuideDetailsHeader';
import GuideDetailsContent from '@/components/guides/GuideDetailsContent';
import BulkGuideCopyDrawer from '@/components/guides/list/bulk-guide-copy/BulkGuideCopyDrawer';
import GuideAuditDrawer from '@/stateless-components/guides/audit/GuideAuditDrawer';
import { PendoLoading } from '@pendo/components';
import { isCrossApp } from '@pendo/services/CrossAppGuides';
import { loadSourceCountables, eligibleSubscriptions } from '@/utils/copy-guides';
import { MOBILE_PLATFORMS } from '@/stateless-components/utils/apps';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
    areRevisionsEqual,
    convertGuideHistoryToSessions,
    isDateBeforeDefaultStartDate,
    guideHistoryObservable
} from '@/stateless-components/guides/audit/guide-audit-utils';
import GuideContentValidation from '@/components/guides/list/GuideContentValidation.vue';
import ClearGuideDataModal from '@/components/ClearGuideDataModal.vue';
import LaunchPreviewModal from '@/stateless-components/modals/LaunchPreviewModal.vue';
import DeleteGuideModal from '@/components/DeleteGuideModal.vue';

export default {
    name: 'GuideDetailsView',
    components: {
        GuideDetailsHeader,
        GuideDetailsContent,
        BulkGuideCopyDrawer,
        GuideAuditDrawer,
        GuideContentValidation,
        ClearGuideDataModal,
        LaunchPreviewModal,
        DeleteGuideModal
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            showGuideCopyModal: false,
            loadingSourceCountables: false,
            showHistoryDrawer: false,
            isHistoryLoading: false,
            history: [],
            selectedRevision: null,
            showGuideValidationModal: false,
            showClearGuideDataModal: false,
            launchPreviewModalProps: {},
            showLaunchPreviewModal: false,
            deleteGuideModalProps: {},
            showDeleteGuideModal: false
        };
    },
    computed: {
        ...mapState({
            isFetchingGuides: (state) => state.guides.isFetching,
            guideId: (state) => state.route.params.id,
            activeGuideId: (state) => state.guides.activeId,
            segmentsMap: (state) => state.filters.segmentsMap,
            user: (state) => state.auth.user
        }),
        ...mapGetters({
            guide: 'guides/active',
            guides: 'guides/list',
            getAppById: 'apps/appById',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            activeIsTrainingSubscription: 'subscriptions/activeIsTrainingSubscription',
            activeTimezone: 'subscriptions/getTimezone',
            featuresNamesById: 'features/namesById',
            pagesNamesById: 'pages/namesById'
        }),
        isCrossApp () {
            return isCrossApp(this.guide);
        },
        appId () {
            const crossAppId = get(this.guide, 'steps[0].appIds[0]', '');

            return this.isCrossApp ? crossAppId : this.guide.appId;
        },
        isMobileApp () {
            const app = this.getAppById(this.appId);
            const platform = get(app, 'platform', '');

            return MOBILE_PLATFORMS.includes(platform);
        },
        isLoading () {
            return this.isFetchingGuides || !this.activeGuideId;
        },
        eligibleSubscriptions () {
            return eligibleSubscriptions();
        },
        guideRevisions () {
            return convertGuideHistoryToSessions(
                this.history,
                this.activeTimezone,
                this.featuresNamesById,
                this.pagesNamesById
            );
        },
        isFullHistoryLoaded () {
            return (
                this.history.length > 0 && this.history[this.history.length - 1].lastUpdatedAt === this.guide.createdAt
            );
        }
    },
    async created () {
        const fetchingPromise = this.fetchGuides();
        if (this.isFetchingGuides) {
            await fetchingPromise;
        }

        this.setActiveGuideById({ id: this.guideId });

        if (isEmpty(this.guide)) return this.$router.push({ name: 'notFound' });

        const { appId } = this.guide;
        await Promise.all([this.updateGuidePreviewable({ appId })]);

        this.formatDocumentTitleByRoute();
    },
    methods: {
        ...mapActions({
            updateGuidePreviewable: 'guides/updateGuidePreviewable',
            fetchGuides: 'guides/fetch',
            formatDocumentTitleByRoute: 'router/formatDocumentTitleByRoute'
        }),
        ...mapMutations({
            setActiveGuideById: 'guides/setActiveById'
        }),
        openGuideCopyDrawer () {
            this.showGuideCopyModal = true;
        },
        openHistoryDrawer () {
            this.getGuideHistory();
            this.showHistoryDrawer = true;
        },
        closeGuideCopyModal () {
            this.showGuideCopyModal = false;
        },
        closeHistoryDrawer () {
            this.showHistoryDrawer = false;
        },
        handleHistoryUpdate (history) {
            this.history = history;
            if (!this.isFullHistoryLoaded && this.guideRevisions.length < 2) return;
            const revision = this.selectedRevision || this.guideRevisions[0];
            this.updateSelection(revision);
            this.isHistoryLoading = false;
        },
        async getGuideHistory () {
            this.isHistoryLoading = true;
            const historyObservable = await guideHistoryObservable(this.guide);
            historyObservable.subscribe({
                next: this.handleHistoryUpdate
            });
        },
        setHistoryLoading (start) {
            this.isHistoryLoading = !this.isFullHistoryLoaded && isDateBeforeDefaultStartDate(start, this.guide);
        },
        updateSelection (revision) {
            this.selectedRevision = revision;
            const lastRevision = this.guideRevisions[this.guideRevisions.length - 1];
            const isSelectedRevisionLastRevision = areRevisionsEqual(lastRevision, revision);

            if (isSelectedRevisionLastRevision) {
                this.setHistoryLoading(this.guide.createdAt);
            }
        },
        async onBulkCopyOpen ({ pages, features }) {
            this.loadingSourceCountables = true;

            await loadSourceCountables({ pages, features });

            this.loadingSourceCountables = false;
        },
        openGuideValidationModal () {
            this.showGuideValidationModal = true;
        },
        openClearGuideDataModal () {
            this.showClearGuideDataModal = true;
        },
        openLaunchPreviewModal ($event) {
            this.launchPreviewModalProps = $event;
            this.showLaunchPreviewModal = true;
        },
        closeLaunchPreviewModal () {
            this.showLaunchPreviewModal = false;
            this.launchPreviewModalProps = {};
        },
        openDeleteGuideModal ($event) {
            this.deleteGuideModalProps = $event;
            this.showDeleteGuideModal = true;
        },
        closeDeleteGuideModal () {
            this.showDeleteGuideModal = false;
            this.deleteGuideModalProps = {};
        }
    }
};
</script>
<style lang="scss" scoped>
.loading-empty-state {
    height: 100%;
    width: 100%;
}
</style>
