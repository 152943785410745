<template>
    <div class="guide-audit-step-details">
        <pendo-collapse
            v-for="(step, index) in stepsToDisplay"
            :key="`step-card-${index}`"
            class="step-card"
            data-cy="guide-audit-step"
            body-min-height="auto">
            <pendo-collapse-item
                :title="step.name"
                :expanded="step.expanded"
                class="body">
                <pendo-collapse
                    class="step-changes"
                    body-min-height="auto">
                    <pendo-collapse-item
                        :key="`visual-changes-${selectedRevision.lastUpdatedAt}`"
                        title="Visual changes"
                        class="visual-changes"
                        :name="`visual-changes-${index}`"
                        :expanded="step.expanded && step.hasVisualChanges"
                        @click="toggleStepView(index)">
                        <differences-display
                            :show-previous-revision="showPreviousRevision"
                            :is-removed="step.isRemoved"
                            :is-added="step.isAdded"
                            :selected-revision-subtitle="selectedRevisionSubtitle"
                            :previous-revision-subtitle="previousRevisionSubtitle">
                            <template
                                v-if="step.selected"
                                #selected>
                                <guide-step-preview
                                    v-if="stepsToDisplay[index].expanded"
                                    :key="`preview-selected-${step.selected.lastUpdatedAt}-${step.selected.id}`"
                                    :dom="step.selected.dom"
                                    :step="step.selected"
                                    :guides="[
                                        ...allGuidesButSelected,
                                        { ...selectedRevision, id: step.selected.guideId }
                                    ]"
                                    :resource-centers="[]"
                                    :debounce-time="2" />
                            </template>
                            <template
                                v-if="step.previous"
                                #previous>
                                <guide-step-preview
                                    v-if="stepsToDisplay[index].expanded"
                                    :key="`preview-previous-${step.previous.lastUpdatedAt}-${step.previous.id}`"
                                    :dom="step.previous.dom"
                                    :step="step.previous"
                                    :guides="[
                                        ...allGuidesButSelected,
                                        { ...previousRevision, id: step.previous.guideId }
                                    ]"
                                    :resource-centers="[]"
                                    :debounce-time="2" />
                            </template>
                        </differences-display>
                    </pendo-collapse-item>
                    <pendo-collapse-item
                        v-if="!step.isRemoved && step.nonVisualChanges.length > 0"
                        title="Other changes"
                        class="other-changes"
                        :name="`other-changes-${index}`"
                        :expanded="step.expanded && step.nonVisualChanges.length > 0">
                        <differences-display
                            :is-removed="step.isRemoved"
                            :is-added="step.isAdded"
                            :show-tags="false"
                            :show-previous-revision="showPreviousRevision"
                            :selected-revision-subtitle="selectedRevisionSubtitle"
                            :previous-revision-subtitle="previousRevisionSubtitle"
                            :differences="step.nonVisualChanges" />
                    </pendo-collapse-item>
                </pendo-collapse>
            </pendo-collapse-item>
        </pendo-collapse>
    </div>
</template>

<script>
import { PendoCollapse, PendoCollapseItem } from '@pendo/components';
import { BuildingBlock, BuildingBlockProperties, BuildingBlockUtils } from '@pendo/services/BuildingBlocks';
import GuideStepPreview from '@/components/guides/settings/GuideStepPreview';
import { getBuildingBlocks } from '@/stateless-components/utils/guides';
import {
    getNonVisualChanges,
    stepHasVisualChanges,
    getBuildingBlocksChanges,
    mergeStepAndBuildingBlockChanges,
    GUIDE_AUDIT_PROPS_TO_CHECK
} from '@/stateless-components/guides/audit/guide-audit-utils';
import DifferencesDisplay from './differences-display/DifferencesDisplay';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import isNull from 'lodash/isNull';
import has from 'lodash/has';

export default {
    name: 'GuideAuditStepDetails',
    components: {
        GuideStepPreview,
        PendoCollapseItem,
        PendoCollapse,
        DifferencesDisplay
    },
    props: {
        selectedRevision: {
            type: Object,
            default: null
        },
        previousRevision: {
            type: Object,
            default: null
        },
        previousRevisionSubtitle: {
            type: String,
            default: ''
        },
        selectedRevisionSubtitle: {
            type: String,
            default: ''
        },
        showPreviousRevision: {
            type: Boolean,
            default: true
        },
        isEmbedded: {
            type: Boolean,
            default: false
        },
        isMobileApp: {
            type: Boolean,
            default: false
        },
        guides: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            showPreviewOfIndex: {},
            stepsToDisplay: [],
            loadingSteps: false,
            needsToCollapse: {},
            STATUS_PROP_KEY: GUIDE_AUDIT_PROPS_TO_CHECK.STATE,
            panels: [
                {
                    name: 'other',
                    title: 'Other changes'
                }
            ]
        };
    },
    computed: {
        selectedRevisionSteps () {
            return get(this, 'selectedRevision.steps', []);
        },
        previousRevisionSteps () {
            return get(this, 'previousRevision.steps', []);
        },
        previousRevisionStepsMap () {
            return keyBy(this.previousRevisionSteps, 'id');
        },
        selectedRevisionStepsMap () {
            return keyBy(this.selectedRevisionSteps, 'id');
        },
        allGuidesButSelected () {
            return this.guides.filter((guide) => guide.id !== this.selectedRevision.id);
        }
    },
    watch: {
        async selectedRevision () {
            console.log('selected revision changed');
            await this.loadAndDisplaySteps();
        }
    },
    async mounted () {
        await this.loadAndDisplaySteps();
    },
    methods: {
        async loadAndDisplaySteps () {
            this.loadingSteps = true;
            this.stepsToDisplay = [];
            await this.populateStepsToDisplay();
            this.loadingSteps = false;
        },
        isAdded (stepId) {
            return (
                !isNull(stepId) &&
                has(this.selectedRevisionStepsMap, stepId) &&
                !has(this.previousRevisionStepsMap, stepId)
            );
        },
        isRemoved (stepId) {
            return (
                !isNull(stepId) &&
                !has(this.selectedRevisionStepsMap, stepId) &&
                has(this.previousRevisionStepsMap, stepId)
            );
        },
        isPresentOnBoth (stepId) {
            return has(this.selectedRevisionStepsMap, stepId) && has(this.previousRevisionStepsMap, stepId);
        },
        async populateStepsToDisplay () {
            const { selectedRevisionSteps, previousRevisionSteps } = this;
            console.log('populate', selectedRevisionSteps, previousRevisionSteps);
            const stepsToDisplay = [];
            let selectedIndex = 0;
            let previousIndex = 0;

            let isIndexWithinSelected = selectedIndex < selectedRevisionSteps.length;
            let isIndexWithinPrevious = previousIndex < previousRevisionSteps.length;

            while (isIndexWithinSelected || isIndexWithinPrevious) {
                const selectedStep = selectedRevisionSteps.at(selectedIndex);
                const prevAtIndex = previousRevisionSteps.at(previousIndex);
                const prevAtIndexId = get(prevAtIndex, 'id', null);
                const selectedId = get(selectedStep, 'id', null);
                const stepToDisplay = {};

                if (this.isRemoved(prevAtIndexId)) {
                    stepToDisplay.selected = null;
                    stepToDisplay.previous = prevAtIndex;
                    stepToDisplay.showPreviousRevision = true;
                    stepToDisplay.isRemoved = true;
                    previousIndex++;
                    stepsToDisplay.push(stepToDisplay);
                } else if (this.isAdded(selectedId)) {
                    stepToDisplay.selected = selectedStep;
                    stepToDisplay.previous = null;
                    stepToDisplay.showPreviousRevision = false;
                    selectedIndex++;
                    stepToDisplay.indexToDisplay = selectedIndex;
                    stepToDisplay.isAdded = true;
                    stepsToDisplay.push(stepToDisplay);
                } else if (this.isPresentOnBoth(prevAtIndexId)) {
                    stepToDisplay.selected = selectedStep;
                    stepToDisplay.previous = this.previousRevisionStepsMap[selectedStep.id];
                    stepToDisplay.showPreviousRevision = true;
                    selectedIndex++;
                    stepToDisplay.indexToDisplay = selectedIndex;
                    previousIndex++;
                    stepsToDisplay.push(stepToDisplay);
                } else {
                    selectedIndex++;
                    previousIndex++;
                    stepToDisplay.indexToDisplay = selectedIndex;
                }

                isIndexWithinSelected = selectedIndex < selectedRevisionSteps.length;
                isIndexWithinPrevious = previousIndex < previousRevisionSteps.length;
            }

            const allRequests = stepsToDisplay.map(this.getStepData);
            this.stepsToDisplay = await Promise.all(allRequests);
        },
        async getDomAndBlocks (stepRevision) {
            let step = cloneDeep(stepRevision);
            let { buildingBlocks, dom } = await getBuildingBlocks(stepRevision);
            buildingBlocks = JSON.parse(buildingBlocks);
            step = { ...step, buildingBlocks, dom };

            return step;
        },
        async getStepData (step) {
            const stepToDisplay = cloneDeep(step);
            if (stepToDisplay.selected) {
                stepToDisplay.selected = await this.getDomAndBlocks(stepToDisplay.selected);
            }

            if (stepToDisplay.previous) {
                stepToDisplay.previous = await this.getDomAndBlocks(stepToDisplay.previous);
            }

            const { selected, previous } = stepToDisplay;
            const selectedBuildingBlocks = get(selected, 'buildingBlocks');
            const previousBuildingBlocks = get(previous, 'buildingBlocks');
            const { isEmbedded, isMobileApp } = this;
            const buildingBlockChanges = getBuildingBlocksChanges(selectedBuildingBlocks, previousBuildingBlocks, {
                isEmbedded,
                isMobileApp
            });
            const hasVisualChanges = stepHasVisualChanges(selected, previous);
            const nonVisualChanges = getNonVisualChanges(selected, previous, { isEmbedded });

            const isPreviousBannerStep = this.isBannerStep(previous);
            const isSelectedBannerStep = this.isBannerStep(selected);

            stepToDisplay.isBannerStep = isSelectedBannerStep || isPreviousBannerStep;
            stepToDisplay.hasVisualChanges = hasVisualChanges;
            stepToDisplay.nonVisualChanges = mergeStepAndBuildingBlockChanges(nonVisualChanges, buildingBlockChanges);
            stepToDisplay.name = this.getStepTitle(stepToDisplay);
            stepToDisplay.expanded = hasVisualChanges || nonVisualChanges.length > 0;

            return stepToDisplay;
        },
        getStepTitle ({ indexToDisplay, hasVisualChanges, nonVisualChanges, isAdded, isRemoved }) {
            if (isRemoved) {
                return 'Removed';
            }

            let suffix = '';
            if (!hasVisualChanges && !nonVisualChanges.length && !isAdded) {
                suffix = ' (No changes)';
            }

            return `Step ${indexToDisplay}${suffix}`;
        },
        toggleStepView (index) {
            this.stepsToDisplay[index].expanded = !this.stepsToDisplay[index].expanded;
        },
        isBannerStep (step) {
            if (!step) return false;
            if (!step.buildingBlocks) return false;

            const { buildingBlocks } = step;
            const guideBlock = BuildingBlock.findBlockByDomId(buildingBlocks, 'pendo-g-');
            const guideBlockLayoutProperties = BuildingBlockProperties.getGuideBlockLayoutProperties(guideBlock);
            const stepWidth = get(guideBlockLayoutProperties, 'width.value');
            if (!stepWidth) {
                return true;
            }
            if (stepWidth.includes('%')) {
                if (parseInt(stepWidth) >= 50) {
                    return true;
                }

                const stepMinWidth = get(guideBlockLayoutProperties, 'minWidth.value');
                if (stepMinWidth && parseInt(BuildingBlockUtils.dpToPx(stepMinWidth)) > 700) {
                    return true;
                }
            } else if (parseInt(BuildingBlockUtils.dpToPx(stepWidth)) > 700) {
                return true;
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.guide-audit-step-details {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;

    .step-card {
        border: 1px solid $color-gray-40;

        .pendo-collapse-item.body {
            > :deep(.pendo-collapse-item__header) {
                font-size: 18px;
                font-weight: 400;
                line-height: 1em;
            }
        }

        .pendo-collapse-item:last-child {
            margin-bottom: 0px;
        }

        :deep(.pendo-collapse) {
            width: 98%;
            padding-top: 16px;
            border: none;
        }

        :deep(.pendo-collapse-item__header.is-expanded) {
            border-bottom: 1px solid $color-gray-40;
        }

        :deep(.pendo-collapse-item__content) {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
        }

        .step-changes {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 24px;

            :deep(.pendo-collapse-item__header) {
                font-weight: bold;
                height: 36px;
                line-height: 36px;
                background: $color-gray-10;
            }

            :deep(.pendo-collapse-item__content) {
                padding-top: 16px;
            }
        }
    }
}
</style>
