<template>
    <div class="resource-chooser">
        <div class="resource-chooser__filters">
            <pendo-multiselect
                :value="selectedResourceType"
                :options="resourceTypeOptions"
                :disabled="disabled"
                placeholder="Select event type"
                @select="onResourceTypeSelect">
                <template #trigger>
                    <pendo-data-measure-trigger />
                </template>
                <template #option="{ option }">
                    <pendo-icon-option :option="option" />
                </template>
            </pendo-multiselect>
            <template v-if="usesMultiApp">
                <span class="resource-chooser__text">from</span>
                <pendo-multiselect
                    :value="selectedApp"
                    :options="appOptions"
                    :disabled="disabled"
                    :placeholder="isLoading ? 'Loading...' : 'Select an app'"
                    @select="onAppSelect">
                    <template #option="{ option }">
                        <pendo-app-display :apps="option" />
                    </template>
                    <template #trigger>
                        <pendo-data-measure-trigger />
                    </template>
                </pendo-multiselect>
            </template>
        </div>
        <pendo-multiselect
            :value="resource"
            :loading="isLoading"
            :placeholder="isLoading ? 'Loading...' : resourcePlaceholder"
            :searchable="false"
            :options="resourceOptions"
            :disabled="disabled"
            label-key="name"
            :min-menu-width="300"
            :no-results-text="`No ${selectedResourceType.label}s found. Consider changing the search query.`"
            @select="onResourceSelect">
            <template #header="{ updateInputValue }">
                <div class="resource-chooser__search">
                    <pendo-multiselect-input
                        class="resource-chooser__search-input"
                        :update-input-value="updateInputValue"
                        is-search-icon-visible
                        :computed-placeholder="inputPlaceholder" />
                </div>
            </template>
            <template #selectedLabel="{ option }">
                <pendo-icon-option :option="option" />
            </template>
            <template #trigger>
                <div
                    class="resource-chooser__trigger"
                    data-cy="resource-chooser">
                    <pendo-data-source-trigger />
                </div>
            </template>
            <template #option="{ option }">
                <pendo-icon-option :option="option" />
            </template>
            <template #noData>
                <div class="no-data-message">
                    <span v-if="usesMultiApp">
                        No {{ selectedResourceType.label }}s found. Please select a different app or data source.
                    </span>
                    <span v-else> No {{ selectedResourceType.label }}s found. </span>
                </div>
            </template>
        </pendo-multiselect>
    </div>
</template>
<script>
import {
    PendoMultiselect,
    PendoDataSourceTrigger,
    PendoDataMeasureTrigger,
    PendoIconOption,
    PendoMultiselectInput,
    PendoAppDisplay
} from '@pendo/components';
import { mapGetters, mapActions } from 'vuex';
import get from 'lodash/get';

const PAGE_KIND = 'Page';
const FEATURE_KIND = 'Feature';
const TRACK_TYPE_KIND = 'TrackType';

export default {
    name: 'ResourceChooser',
    components: {
        PendoMultiselect,
        PendoDataSourceTrigger,
        PendoDataMeasureTrigger,
        PendoIconOption,
        PendoMultiselectInput,
        PendoAppDisplay
    },
    props: {
        id: {
            type: String,
            default: undefined
        },
        kind: {
            type: String,
            default: undefined
        },
        // resource IDs are stored like {pageId: 'foo'} etc. in paths/funnels
        kindIdContainer: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            default: false
        },
        appList: {
            type: Array,
            default: () => []
        }
    },
    emits: ['select'],
    data () {
        return {
            isLoading: true,
            resourceTypeOptions: [
                { id: PAGE_KIND, label: 'Page', icon: { type: 'file' } },
                { id: FEATURE_KIND, label: 'Feature', icon: { type: 'feature' } }
            ],
            selectedResourceType: undefined,
            selectedApp: undefined
        };
    },
    computed: {
        ...mapGetters({
            getPageById: 'pages/pageById',
            getFeatureById: 'features/featureById',
            getTrackEventById: 'trackEvents/trackEventById',
            pageList: 'pages/list',
            featureList: 'features/list',
            trackEventsList: 'trackEvents/list',
            activeApp: 'apps/active',
            usesMultiApp: 'subscriptions/usesMultiApp',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        resourceId () {
            if (this.id) {
                return this.id;
            }

            const { pageId, featureId, trackTypeId } = this.kindIdContainer;

            return pageId || featureId || trackTypeId;
        },
        resourceKind () {
            if (this.kind) {
                return this.kind;
            }

            const { pageId, featureId, trackTypeId } = this.kindIdContainer;

            if (pageId) {
                return PAGE_KIND;
            }

            if (featureId) {
                return FEATURE_KIND;
            }

            if (trackTypeId) {
                return TRACK_TYPE_KIND;
            }

            return undefined;
        },
        resource () {
            switch (this.resourceKind) {
                case PAGE_KIND:
                    return this.getPageById(this.resourceId);
                case FEATURE_KIND:
                    return this.getFeatureById(this.resourceId);
                case TRACK_TYPE_KIND:
                    return this.getTrackEventById(this.resourceId);
                default:
                    return undefined;
            }
        },
        resourceOptions () {
            if (!this.selectedResourceType) {
                return [];
            }

            let options = [];
            switch (this.selectedResourceType.id) {
                case PAGE_KIND:
                    options = this.pageList;
                    break;
                case FEATURE_KIND:
                    options = this.featureList;
                    break;
                case TRACK_TYPE_KIND:
                    options = this.trackEventsList;
                    break;
            }

            return options
                .filter((resource) => {
                    return resource.appId === this.appToFilterBy.id;
                })
                .map((option) => {
                    return {
                        ...option,
                        kind: option.kind || this.selectedResourceType.id
                    };
                });
        },
        resourcePlaceholder () {
            return `Select a ${this.selectedResourceType.label}`;
        },
        inputPlaceholder () {
            return `Search ${this.selectedResourceType.label}s...`;
        },
        appToFilterBy () {
            const app = this.usesMultiApp ? this.selectedApp : this.activeApp;

            return app || {};
        },
        appOptions () {
            return this.appList.map((app) => {
                return {
                    id: app.id,
                    applicationFlags: app.applicationFlags,
                    trainingAttributes: app.trainingAttributes,
                    label: app.displayName,
                    platform: app.platform,
                    displayName: app.displayName,
                    color: get(app, 'color', null),
                    faviconB64: get(app, 'faviconB64', null)
                };
            });
        }
    },
    async created () {
        this.isLoading = true;
        if (this.hasSegmentFlag('adoptTrackEvent')) {
            this.resourceTypeOptions.push({ id: TRACK_TYPE_KIND, label: 'Track Event', icon: { type: 'zap' } });
        }
        this.selectedResourceType = this.resourceKind
            ? this.resourceTypeOptions.find((option) => option.id === this.resourceKind)
            : this.resourceTypeOptions[0];

        this.selectedApp = this.resource
            ? this.appOptions.find((option) => option.id === this.resource.appId)
            : this.appOptions[0];

        await Promise.all([this.loadAllPages(), this.loadAllFeatures(), this.loadAllTrackEvents()]);

        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            loadAllPages: 'pages/fetch',
            loadAllFeatures: 'features/fetch',
            loadAllTrackEvents: 'trackEvents/fetch'
        }),
        onResourceSelect (resource) {
            this.$emit('select', { ...resource });
        },
        onResourceTypeSelect (resourceType) {
            this.selectedResourceType = resourceType;
            this.$emit('select', {});
        },
        onAppSelect (app) {
            this.selectedApp = app;
            this.$emit('select', {});
        }
    }
};
</script>

<style scoped lang="scss">
.resource-chooser {
    &__search {
        border-bottom: 1px solid $gray-lighter-6;
        padding: 16px;

        :deep(.pendo-multiselect__control) {
            grid-auto-columns: auto;
        }
    }

    &__search-input {
        padding: 0 8px;
        height: 34px;
        border: 1px solid $gray-lighter-6;
        border-radius: 3px;
        justify-content: start;
    }

    &__filters > * {
        margin-right: 6px;
    }

    &__text {
        font-size: 16px;
        color: $gray-lighter-2;
    }
}
</style>
