import get from 'lodash/get';
import intersection from 'lodash/intersection';
import cloneDeep from 'lodash/cloneDeep';
import { getDisplayName } from '@pendo/services/Apps';
import { PENDO_APP_NAMES } from '@pendo/services/Constants';
import { http } from '@pendo/http';
import { filterIsAllApps } from './filters';

export const PROPERTY_ENDPOINT_MAP = {
    extensionDomainPatterns: 'extensionDomainPatterns'
};

export const FILTER_TYPES = Object.freeze({
    APP_ID: 'appId',
    APP_IDS: 'appIds'
});

export async function meetsMinimumAgentVersion (version, appId, isDigitalAdoptionSub = false) {
    // Probably not the optimal solution, but DAP subs don't manage an agent, and are generally on latest
    if (isDigitalAdoptionSub) {
        return true;
    }

    const agentVersionsMap = await http
        .get('/api/s/_SID_/environment/versioncheck', {
            params: {
                v: version // eslint-disable-line id-length
            }
        })
        .then((res) => res.data);
    const { production } = get(agentVersionsMap, appId, false);

    return production;
}

// Default web app `-` needs to be converted to _
export function convertAppIdToAggSafeId (appId) {
    return String(appId).replace('-', '_');
}

export function generateAppUid (subId, appId) {
    return `${subId}:${appId}`;
}

export function getEndpointForProperty (propertyName) {
    return PROPERTY_ENDPOINT_MAP[propertyName];
}

export function updateAppProperty (id, propertyName, propertyValue) {
    const endpoint = getEndpointForProperty(propertyName);
    const formattedPropertyValue = formatValueForWebRequest(propertyName, propertyValue);

    return http.put(`/api/s/_SID_/application/${id}/${endpoint}`, formattedPropertyValue).then((res) => res.data);
}

export function formatValueForWebRequest (propertyName, propertyValue) {
    switch (propertyName) {
        case 'extensionDomainPatterns':
            return { extensionDomainPatterns: propertyValue };
        default:
            return propertyValue;
    }
}

export function updateApp (app) {
    return http.put(`/api/s/_SID_/application/${app.id}`, app).then((res) => res.data);
}

export function updateAppFlag (id, flag, enabled) {
    return http.put(`/api/s/_SID_/application/${id}/flag/${flag}`, { set: enabled }).then((res) => res.data);
}

export function fetchExtensionPlatformTypes () {
    return http.get('/api/s/_SID_/daextension/schema/types').then((res) => res.data);
}

export function filterEntitiesByAppKeys (entityList = [], appIdFilter = []) {
    return filterEntities(entityList, appIdFilter, FILTER_TYPES.APP_IDS);
}

export function filterEntitiesByAppIds (entityList = [], appIdFilter = []) {
    return filterEntities(entityList, appIdFilter, FILTER_TYPES.APP_ID);
}

export function filterEntities (entityList = [], appIdFilter = [], type) {
    if (filterIsAllApps(appIdFilter)) {
        return entityList;
    }

    const byAppId = (entity) => appIdFilter.includes(entity.appId);
    const byAppIds = (entity) => !!intersection(appIdFilter, entity.appIds).length;

    switch (type) {
        case FILTER_TYPES.APP_ID:
            return entityList.filter(byAppId);
        case FILTER_TYPES.APP_IDS:
            return entityList.filter(byAppIds);
        default:
            return entityList;
    }
}

export function addAppToEntityList (entityList = [], appMap = []) {
    return entityList.map((entity) => {
        const entityClone = cloneDeep(entity);
        const defaultAppKeys = entityClone.appIds || [entityClone.appId];
        entityClone.app = entityClone.appIds ? appMap[entityClone.appIds[0]] : appMap[entityClone.appId];
        entityClone.apps = defaultAppKeys.map((appId) => appMap[appId]);

        return entityClone;
    });
}

export function getDesignerAppName (adoptDADesigner, type = 'guide') {
    return type === 'guide' && adoptDADesigner ? PENDO_APP_NAMES.ADOPT_STUDIO : PENDO_APP_NAMES.VDS;
}

export function commaSeparatedAppList (apps) {
    return apps.map(getDisplayName).join(', ');
}
